/* ========================================= */
/*            Default           */
/* ========================================= */

.section-title{padding-bottom: 30px;}

.section-title h2 {
    margin-bottom: 0;
    position: relative;
    padding-left: 15px;
}

.section-title h2:before {
    content: '';
    background: #fda300;
    position: absolute;
    top: 0;
    height: 100%;
    width: 3px;
    left: 0px;
}

/*breaking news*/

.breaking_news{position: relative; margin-top: 50px;} 

.breaking_inner {
    border: 1px solid #f1f1f1;
    background: #fbfbfb;
    padding: 13px 0 8px;
    border-radius: 0 10px;
    position: relative;
    overflow: hidden;
}

.breaking_news h3 {
    position: absolute;
    background: #333a65;
    padding: 8px 15px;
    border-radius: 0 0px 0 10px;
    z-index: 1;
    top: 0;
    left: 0;
}

.breaking_news marquee{padding-left: 100px;} 

.breaking_news marquee a {
    padding-left: 30px;
}

@media(max-width: 639px){
  .breaking_news h3{position: inherit; border-radius: 0; text-align: center;}

  .breaking_news marquee{padding:15px 0px 0; margin:0 20px;}
}

/* ============================= */
/*          Blog Section         */
/* ============================= */

#mt_blog .blog-post_wrapper{
    position: relative;
    z-index: 0;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 380px; 
    transition: all ease-in-out 0.5s;
    border-radius: 0 26px;
    background-color: #eaedf6;
}

#mt_blog .blog-post_wrapper:hover {
    transition: all ease-in-out 0.5s;
}

#mt_blog .blog-post_wrapper.image-wrapper:before {
    content: '';
    background: #00000057;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
}

#mt_blog .blog-post_wrapper.image-wrapper .post-content{
    position: absolute;
    top: 0;
    padding: 40px 38px;
    height: 100%;
}
/*
#mt_blog .blog-post_wrapper h2 a{display: inline-block;}*/

#mt_blog .blog-post_wrapper .post-date p{
    margin: 10px 0;
    font-size: 15px;
    font-weight: 300; 
    color: #666;
}


#mt_blog .blog-post_wrapper .post-date p a{color: #fff;}

#mt_blog .blog-post_wrapper.front-wrapper{
  padding: 40px 38px;
}
#mt_blog .blog-post_wrapper.front-wrapper.bg-gray a{
  color: #333a65; 
  background: linear-gradient(to right, #333a65, #333a65);
  background-position: left 87%;
  background-repeat: no-repeat;
  transition: all ease-in-out 0.5s;
  background-size: 0px 3px;
  padding-bottom: 10px;
}

#mt_blog .blog-post_wrapper.front-wrapper.bg-gray a:hover{
  text-decoration: none !important;
  background-size: 100% 3px;
  transition: all ease-in-out 0.5s;
}

#mt_blog .item-meta a {
    font-size: 16px;
    font-weight: 700;
    position: relative;
    z-index: 2;
    text-align: left;
    line-height: 1.5;
}

#mt_blog .blog-post_wrapper .post-content{position: relative; z-index: 2;}

#mt_blog .blog-post_wrapper h2{
  margin-bottom: 15px;
  line-height: 1.5;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  max-height: 4.5em;
  color: #fff;
}  

#mt_blog .blog-post_wrapper .blog-post-image img{
  width: 100%; 
  transition: all ease-in-out 0.5s
}

#mt_blog .blog-post_wrapper:hover img{
  transform: scale(1.1);
  transition: all ease-in-out 0.5s
}

.post-category li{
    display: inline-block;
    padding: 6px 15px;
    border-radius: 26px;
    margin-right: 5px;
    margin-bottom: 9px;
}

.post-category li a{
  background:transparent; 
}

#mt_blog .blog-post_wrapper.front-wrapper.bg-gray .post-category li a:hover{
  background: transparent;
}

#mt_blog.nomargin{
  margin-top: 50px;
}

#mt_blog.tag-list .blog-post_wrapper{height: 220px!important;}

#mt_blog.tag-list .post-content p {
    line-height: 1.5;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    max-height: 4.5em;
    margin-bottom: 0;
}   

.post-list-main{display: inline-block; width: 100%;} 

.post-list-main .entry-thumb{width: 112px; float: left;}

.post-list-main .thumb-wrap, .category-item .thumb-wrap{
    border-radius:0 26px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    overflow: hidden;
}

.post-list-main .thumb-wrap img, .category-item .thumb-wrap img{
 transition: all ease-in-out 0.5s;  
}

.post-list-main .thumb-wrap:hover img, .category-item .thumb-wrap:hover img{
  transform: scale(1.2);
  transition: all ease-in-out 0.5s;
}

.post-list-main .content-entry-wrap {
    padding-left: 15px;
    width: calc(100% - 112px);
    float: left;
}

.post-list-main .post-category li a{background-image: none;}

.post-list-main .content-entry-wrap a, .category-item .content-entry-wrap a{
  color:#333a65;
  padding-bottom: 5px; 
  background-image: linear-gradient(to right, #333a65, #333a65);
}

.post-list-main .entry-date a, .category-item .entry-date a{font-size: 13px;}

#mt_blog.category-list .blog-post_wrapper{height: 415px;}

#mt_blog .row.slider-items{margin: 0 -15px;}

@media(max-width: 1100px){
  #mt_blog .blog-post_wrapper, #mt_blog.category-list .blog-post_wrapper{height: 350px;}

  #mt_blog .blog-post_wrapper h2{font-size: 28px;}

  #mt_blog .blog-post_wrapper.image-wrapper .post-content, #mt_blog .blog-post_wrapper.front-wrapper{
    padding: 30px;
  }
}

@media(max-width: 991px){

  #mt_blog .blog-post_wrapper, #mt_blog.category-list .blog-post_wrapper{height: 300px;}

  #mt_blog .blog-post_wrapper, .row.blog-sml-content, section#mt_blog.top-stories article.post-list-main{
    margin-bottom: 30px;
  }

  section#mt_blog.category-list, section#mt_blog.top-stories, section#mt_blog.category-item{
    padding-bottom: 20px;
  }

  section#mt_blog.top-stories .row.mar-bottom-30{ margin-bottom: 0; }

  .blogmargin{margin-top: 30px;}
}

@media(max-width: 767px){

  .row.blog-catmain .content-entry-wrap{margin-bottom: 30px;}
  .category-item .blog-post_wrapper.image-wrapper.mar-top-30 {margin-top: 0;}

  .blogmargin{margin: 0;}

}

@media(max-width: 735px){
}

@media(max-width: 639px){

  section#mt_blog.category-list .col-sm-12, section#mt_blog.top-stories .col-sm-12, section#mt_blog.category-item .col-sm-12{margin: 0;}

  #mt_blog .blog-post_wrapper .blog-post-image img{transform: scale(1.5);}

  #mt_blog .blog-post_wrapper h2{font-size: 24px;}

  .post-list-main .entry-thumb, .post-list-main .content-entry-wrap{width: 100%!important;}

  .post-list-main .content-entry-wrap{padding: 20px 0 0px; margin-bottom: 30px;}

  .post-list-main .entry-thumb img{width: 100%;}

  #mt_blog.top-stories .post-list-main .content-entry-wrap, .row.blog-sml-content{margin-bottom: 0;}

  section#mt_blog.category-post .col-sm-12{margin: 0;}

  section#mt_blog.category-post {
    padding-bottom: 20px;
}

}

@media(max-width: 360px){
  #mt_blog .blog-post_wrapper{height: 340px;}
}

/*ads*/
.ads-banner-img img {
    border-radius: 0 26px;
     border: 3px solid #fbfbfb;
}